<template>
  <div id="why-nasarawa">
    <Hero :slideContent="heroSlide" />

    <div class="mt-10 max-w-screen-lg mx-auto pb-20 w-9/10">
      <div class="text-nasida-green">
        <h3 class="text-lg md:text-2xl">Why Nasarawa?</h3>
        <h1 class="my-6 text-3xl md:text-4xl">A hub for international businesses</h1>
        <p class="text-justify text-nasida-grey">Nasarawa State has significant potentials by virtue of its abundant solid mineral resources, strategic location, rich agricultural and tourism potential, and vast land. The State is primed to be an investment powerhouse and economic hub in Nigeria.</p>
      </div>

      <div class="my-10 space-y-4">
        <div class="p-6 text-white grid md:grid-cols-2 gap-y-8 background">
          <div class="md:border-r text-justify md:text-left border-white flex items-center">
            <p class="md:pr-4 text-lg">The state’s strategic location and business friendly clime attracts enterpreneurs and many multinational companies to situate their offices in the State.</p>
          </div>
          <div class="py-8 md:py-0 md:w-4/5 mx-auto">
            <img
              class="object-contain"
              src="../../assets/images/map.png"
            >
          </div>
        </div>
      </div>

      <div class="my-10">
        <div class="text-nasida-green space-y-6">
          <h1 class="text-4xl md:text-5xl"><b>Discover</b> our five year plan</h1>
          <p class="text-justify text-base text-nasida-grey">The Nasarawa Economic Development Strategy (NEDS 2019-2023) is our road map to becoming one of Nigeria’s top three most competitive states by 2023 for sustained growth, increased economic opportunities and prosperity, social inclusion, and improved quality of life.</p>
        </div>

        <div class="my-10 grid grid-cols-1 gap-6 lg:grid-cols-3 md:mt-20">
          <div class="card">
            <div class="card-text">
              <div class="w-20 md:w-32 lg:w-20">
                <img src="../../assets/icons/reform-areas.svg">
              </div>
              <div class="card-main-text">
                <h3 class="font-bold text-lg">Reform Areas</h3>
                <div class="px-4 w-full">
                  <ul class="px-4 list-disc">
                    <li> Reform of State Civil Service </li>
                    <li> Efficient Tax & revenue Administration </li>
                    <li> Efficient Land administration </li>
                    <li> Robust Economic & financial management </li>
                  </ul>
                </div>
              </div>
            </div>
            <AnchorLink
              class="link"
              text="Explore our plan"
              url="infrastructure-and-thematic-holdings"
              color="white"
            />
            <img src="../../assets/images/who-we-are-image.png">
          </div>
          <div class="card">
            <div class="card-text">
              <div class="w-20 md:w-32 lg:w-20">
                <img src="../../assets/icons/game-changer.svg">
              </div>
              <div class="card-main-text">
                <h3 class="font-bold text-lg">Game Changer Opportunities</h3>
                <div class="px-4 w-full">
                  <ul class="px-4 list-disc">
                    <li> Employment & wealth creation </li>
                    <li> Accelerated Industrialization </li>
                    <li> Infrastructure Development </li>
                    <li> Human capital Development </li>
                    <li> Urban Housing &Sustainable Housing Development </li>
                  </ul>
                </div>
              </div>
            </div>
            <AnchorLink
              class="link"
              text="Explore our plan"
              url="infrastructure-and-thematic-holdings"
              color="white"
            />
            <img src="../../assets/images/who-we-are-image.png">
          </div>
          <div class="card">
            <div class="card-text">
              <div class="w-20 md:w-32 lg:w-20">
                <img src="../../assets/icons/focus-areas.svg">
              </div>

              <div class="card-main-text">
                <h3 class="font-bold text-lg">Focus Areas</h3>
                <div class="px-4 w-full">
                  <ul class="px-4 list-disc">
                    <li> Agriculture </li>
                    <li> Minerals </li>
                    <li> Health and Education </li>
                    <li> Power and Transport </li>
                    <li> Industry, Trade and Tourism </li>
                    <li> Rule of law and Security </li>
                  </ul>
                </div>
              </div>
            </div>
            <AnchorLink
              class="link"
              text="Explore our plan"
              url="infrastructure-and-thematic-holdings"
              color="white"
            />
            <img src="../../assets/images/who-we-are-image.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./why-nasarawa.page.js"></script>
<style src="./why-nasarawa.page.css" scoped lang="css"></style>