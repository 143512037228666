import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import AnchorLink from "@/components/anchor-link/index.vue";
import heroBg from "../../assets/images/test-bg1.png";

export default defineComponent({
  name: "Why Nasarawa",
  components: { Hero, AnchorLink },
  setup() {
    const heroSlide = [
      {
        title: "Invest In Nasarawa",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
});
